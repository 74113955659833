import React, { useState, useCallback, useEffect } from "react";
import Config from '../../config/config';
import { useCookies } from 'react-cookie'
import Service from './topic.service';
import { TopicDTO, TopicInsertDTO } from './topic.dto';
import Header from '../header/header.component';
import readXlsxFile from 'read-excel-file'
import CusMotal from '../header/modal.component';

const Topic = () => {
    const [listTopic, setListTopic] = useState<TopicDTO[]>([])
    const [refresh, setRefresh] = useState(true)
    const [countTotal, setCountTotal] = useState(0);
    const [session] = useCookies(['access_token_session']);
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [lang, setLang] = useState<String>('');
    const [hasSearch, setHasSearch] = useState(false);

    useEffect(() => {
        Service.getTopic(session.access_token_session, 0, lang).then((res) => {
            setListTopic(res.data.topicData);
            setCountTotal(res.data.countTotal);
        }).catch(err => {

        })
    }, [refresh, session, lang])



    const handlePageChange = useCallback(
        () => {
            
            if (listTopic.length < countTotal && window.innerHeight + document.documentElement.scrollTop === document.scrollingElement?.scrollHeight) {
                Service.getTopic(session.access_token_session, listTopic.length, lang).then((res) => {
                    let mList = listTopic.slice(0, listTopic.length);
                    mList.push(...res.data.topicData);
                    setListTopic(mList);
                }).catch(err => {

                })
            }
        }, [session, listTopic, countTotal])

    useEffect(() => {
        window.addEventListener('scroll', handlePageChange);

        return (() => {
            window.removeEventListener('scroll', handlePageChange);
        })
    }, [handlePageChange]);

    const deleteTopicCallback = useCallback(
        (id?: String) => {
            if (window.confirm('Bạn có muốn xóa chủ đề này?')) {
                Service.deleteTopic(session.access_token_session, id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Xóa chủ đề thành công');
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Xóa chủ đề thất bại');
                })
            }
        }, [refresh, session]);

    const updateTopicCallback = useCallback(
        (x: TopicDTO) => {
            console.log(lang);
            const data: TopicDTO = {
                topik: x.topikView,
                topikView: x.topikView,
                topicName: x.topicNameView,
                topicNameView: x.topicNameView,
                topicCreateBy: x.topicCreateBy?._id,
                topicUpdateBy: x.topicUpdateBy,
                topicDelDate: x.topicDelDate,
                status: x.status,
                lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
            }

            if (x._id === '')
                Service.createTopic(session.access_token_session, data).then((res) => {
                    if (res.status === 201) {
                        setIsShow(true);
                        setMessage('Thêm chủ đề thành công');
                        setRefresh(!refresh);
                        setIsEdit(false);
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Thêm chủ đề thất bại');
                })
            else Service.updateTopic(session.access_token_session, data, x._id).then((res) => {
                if (res.status === 200) {
                    setIsShow(true);
                    setMessage('Cập nhật chủ đề thành công');
                    setRefresh(!refresh);
                    setIsEdit(false);
                }
            }).catch(err => {
                setIsShow(true);
                setMessage('Cập nhật chủ đề thất bại');
            })
        }, [refresh, session, lang]);

    const createTopicCallback = useCallback(
        () => {
            if (isEdit || (listTopic.length !== 0 && listTopic[0]._id === ''))
                return;
            let mList = listTopic.slice(0, listTopic.length);
            let data: TopicDTO = {
                topik: 0,
                topikView: 0,
                topicCreateBy: Config.parseJwt(session.access_token_session),
                topicName: '',
                topicNameView: '',
                _id: '',
                topicUpdateBy: Config.parseJwt(session.access_token_session)._id,
                topicDelDate: new Date(),
                status: true,
                lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
            };
            mList.unshift(data)
            setListTopic(mList)
            setIsEdit(true);
        }, [isEdit, listTopic, session, lang])

    const allowUpdateValue = useCallback(
        (index: number) => {
            if (!isEdit) {
                let mList: TopicDTO[] = listTopic.slice(0, listTopic.length);
                mList[index].topicNameView = mList[index].topicName;
                mList[index].topikView = mList[index].topik;
                setListTopic(mList)
                setIsEdit(true);
            }
        }, [isEdit, listTopic]
    )

    const cancelUpdateValue = useCallback(
        (index: number) => {
            let mindex = 0;
            if (listTopic[0]._id === '') {
                mindex = 1;
            }
            let mList = listTopic.slice(mindex, listTopic.length);
            mList[index].topicNameView = null;
            mList[index].topikView = 0;
            setListTopic(mList)
            setIsEdit(false);
        }, [listTopic]
    )

    const updateValue = useCallback(
        (index: number, value: String) => {
            let mList = listTopic.slice(0, listTopic.length);
            mList[index].topicNameView = value;
            setListTopic(mList)
        }, [listTopic]
    )

    const updateTopikValue = useCallback(
        (index: number, value: 0 | 1 | 2 | 3) => {
            let mList: TopicDTO[] = listTopic.slice(0, listTopic.length);
            mList[index].topikView = value;
            setListTopic(mList)
        }, [listTopic]);

    const readExcel = useCallback((event: any) => {
        readXlsxFile(event.target.files[0], { sheet: 'Topic' }).then((rows: any) => {
            console.log(lang);
            if (!!rows) {
                let Topics: any = [];
                rows.map((x: any, index: number) => {
                    if (index !== 0) {
                        const topic: TopicInsertDTO = {
                            lang: lang,
                            topicName: (x[2] && x[2].toString()) || null,
                            topicId: (x[1] && x[1].toString()) || null,
                            topik: (x[3] && Number.parseInt(x[3].toString())) || null,
                            status: true,

                        }
                        Topics.push(topic);
                    }
                    return null;
                });
                console.log(Topics);
                Service.createManyTopics(session.access_token_session, Topics).then((res: any) => {
                    if (res.status === 201) {
                        const success: number = res.data.filter((item: any) => {
                            if (item == null) return false;
                            else return true;
                        }).length;
                        setIsShow(true);
                        setMessage('Thêm thành công ' + success + ' chủ đề, thất bại ' + (res.data.length - success) + ' chủ đề');
                        setRefresh(!refresh)
                    }
                })
            }
        })
    }, [refresh, session, lang]);

    const onSearchResult = useCallback(
        (textSearch: String) => {
            if (textSearch !== '') {
                Service.searchTopic(session.access_token_session, textSearch, lang).then(res => {
                    setListTopic(res.data)
                }).catch(err => { });
                setHasSearch(true);
            } else {
                if (hasSearch) {
                    setRefresh(!refresh);
                    setHasSearch(false);
                }
            }
        }, [session.access_token_session, refresh, lang, hasSearch]);

    const onChangeLanguage = useCallback(
        (language: String) => {
            if (language != lang) {
                console.log("change language", language);

                setLang(language);
            }

        }, []);

    return (
        <div>
            <Header title={'Quản lý chủ đề'} pageActive={'TOPIC'} textSearch={'tên chủ đề...'} onSearchResult={onSearchResult} onChangeLanguage={onChangeLanguage} />
            <table className="table table-hover" style={{ marginTop: 50 }}>
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>STT</th>
                        <th scope="col" style={{ width: '40%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ flex: 2 }}>
                                    Chủ đề
                                </div>
                                {/* <div style={{flex:1, textAlign:'right', marginRight:10}}>
                               Filter
                            </div>
                            <select onChange={onFilterSearch} className="form-control form-control-sm" style={{flex:1}}>
                                <option value={0}>Không chọn</option>    
                                <option value={1}>Topik I</option>
                                <option value={2}>Topik II</option>    
                                <option value={3}>Topik II</option>
                            </select> */}
                            </div>
                        </th>
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Topik</th>
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Người tạo</th>
                        <th scope="col" style={{ width: '20%', textAlign: 'center' }}>
                            <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { createTopicCallback() }}>+</button>
                            <button type="button" className="btn btn-success btn-file" style={{ marginLeft: 10 }}>
                                <input type="file" id="input" onChange={(e) => readExcel(e)} />
                                Nhập Excel
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listTopic.map((x: TopicDTO, index: number) => {
                            return (
                                <tr key={x._id?.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                    <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                    <td>{x.topicNameView != null ? <input className="form-control" placeholder="Tên chủ đề" value={x.topicNameView?.toString()} onChange={(event) => updateValue(index, event.target.value)} /> : x.topicName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            x.topicNameView == null ? x.topik ? 'Topik ' + x.topik : 'Chưa chọn Topik'
                                                :
                                                <div onChange={(e: any) => updateTopikValue(index, e.target.value)} style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                    <div><input type="radio" value={0} name={x._id?.toString()} defaultChecked={x.topikView === 0} />Không chọn</div>
                                                    <div><input type="radio" value={1} name={x._id?.toString()} defaultChecked={x.topikView === 1} />Topik I</div>
                                                    <div><input type="radio" value={2} name={x._id?.toString()} defaultChecked={x.topikView === 2} />Topik II</div>
                                                    <div><input type="radio" value={3} name={x._id?.toString()} defaultChecked={x.topikView === 3} /> Topik III</div>
                                                </div>
                                        }
                                    </td>
                                    <td style={{ textAlign: 'center' }}>{x.topicCreateBy?.fullName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            x.topicNameView != null ?
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => updateTopicCallback(x)}>Xác nhận</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => cancelUpdateValue(index)}>Hủy</button>
                                                </div>
                                                :
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => allowUpdateValue(index)}>Sửa</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => deleteTopicCallback(x._id?.toString())}>Xóa</button>
                                                </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <CusMotal
                isShow={isShow}
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={() => {
                    setIsShow(false);
                }}
                onClose={() => setIsShow(false)}
            />
        </div>
    );
}

export default Topic;

import axios from 'axios';
import { RuleInsertDTO, RuleDTO } from './rulepronce.dto';
import Config from '../../config/config';

const Service = {
    getRule(token: String, skip: number, lang: String): Promise<any> {
        return axios.get<Promise<RuleDTO[]>>(Config.API_URL.RULE.GET + '?skip=' + skip + '&lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    getTopic(token: String, lang: String): Promise<any> {
        return axios.get<Promise<any>>(Config.API_URL.TOPIC.GET_AUTO_COMPLETE + '?lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async deleteRule(token: String, _id: String): Promise<any> {
        return axios.delete<Promise<any>>(Config.API_URL.RULE.DELETE + '?_id=' + _id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })

    },

    async updateRule(token: String, x: any, _id: String): Promise<any> {
        return axios.put<Promise<any>>(Config.API_URL.RULE.UPDATE + '?_id=' + _id, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createRule(token: String, x: any): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.RULE.CREATE, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createManyRule(token: String, x: RuleInsertDTO[]): Promise<any> {
        return axios.post<Promise<RuleDTO[]>>(Config.API_URL.RULE.CREATE_MANY, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async searchRule(token: String, params: String, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.RULE.SEARCH, { lang: lang, keyword: params }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    }
}

export default Service;
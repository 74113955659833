import { useEffect, useState } from 'react';
import './App.css';
import Login from './components/account/login.component';
import SignUp from './components/account/signup.component';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './interceptor';
import { useCookies } from 'react-cookie'
import Topic from './components/topic/topic.component';
import Specialized from './components/specialized/specialized.component';
import Grammar from './components/grammar/grammar.component';
import Grammar90 from './components/choosegrammar/choose.component';
import Grammar100 from './components/choosegrammar/choose100.component';
import RulePronce from './components/rulepronce/rulepronce.component';
import Ads from './components/ads/ads.component';

function App() {
    const [cookies, setCookies] = useCookies(['access_token', 'access_token_session'])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!!cookies.access_token)
            setCookies('access_token_session', cookies.access_token, { path: '/' })
        setIsLoading(false);
    }, [cookies, setCookies])
    if (isLoading) return null;
    return (<Router>
        <Switch>
            <Route exact path='/' component={Topic} />
            <Route path='/topic' component={Topic} />
            <Route path='/specialized' component={Specialized} />
            <Route path='/rule' component={RulePronce} />
            <Route path='/grammar' component={Grammar} />
            <Route path='/topik90' component={Grammar90} />
            <Route path='/topik100' component={Grammar100} />
            <Route path="/sign-in" component={Login} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/ads" component={Ads} />
            <Route exact path='*' component={Topic} />

        </Switch>
    </Router>
    );
}

export default App;

import axios from "axios";
import Config from "../../config/config";


const Service = {
    getAll(token: String): Promise<any> {
        return axios.get<Promise<any>>(Config.API_URL.ADS.GET, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    uploadFile(token: String, formData: FormData): Promise<any> {
        // return axios.get<Promise<any>>(Config.API_URL.ADS.GET, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Beare ' + token
        //     }
        // })
        // { message: string }
        return axios.post<Promise<any>>(Config.API_URL.ADS.UPLOAD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Beare ' + token
            },
        });
    },

    deleteFile(token: string, filename: string): Promise<any> {
        // return axios.get<Promise<any>>(Config.API_URL.ADS.GET, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Beare ' + token
        //     }
        // })
        // { message: string }
        return axios.delete<Promise<any>>(Config.API_URL.ADS.UPDATE + filename, {
            headers: {
                'Content-Type': 'multipart/json',
                'Authorization': 'Beare ' + token
            },
        });
    }
}

export default Service;
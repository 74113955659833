import { useState, useCallback } from 'react';
import axios from 'axios';
import Config from '../../config/config';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CusMotal from '../header/modal.component';

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie] = useCookies([
    'access_token',
    'access_token_session',
  ]);
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [message, setMessage] = useState('');

  const memoizedCallback = useCallback(() => {
    if (userName !== '' && password !== '') {
      //   const myCipher = Config.cipher('account');
      axios
        .post(
          Config.API_URL.LOGIN,
          {
            userName: userName,
            // password: myCipher(password)
            password: password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          if (!!res.data) {
            if (isRemember) {
              let expires = new Date();
              expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
              setCookie('access_token', res.data.token, { path: '/', expires });
              setCookie('access_token_session', res.data.token, { path: '/' });
              history.push('/');
            } else {
              setCookie('access_token_session', res.data.token, { path: '/' });
              history.push('/');
            }
          }
        })
        .catch((err) => {
          setIsShow(true);
          setMessage('Tài khoản hoặc mật khẩu không đúng');
        });
    } else {
      setIsShow(true);
      setMessage('Tài khoản hoặc mật khẩu không được để trống');
    }
  }, [userName, password, isRemember, history, setCookie]);

  const _handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        memoizedCallback();
      }
    },
    [memoizedCallback]
  );

  return (
    <div className='App'>
      <nav className='navbar navbar-expand-lg navbar-light fixed-top'>
        <div className='container'>
          <Link className='navbar-brand' to={'/sign-in'}>
            Tra cứu từ điển
          </Link>
          <div className='collapse navbar-collapse' id='navbarTogglerDemo02'>
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <Link className='nav-link' to={'/sign-in'}>
                  Đăng nhập
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to={'/sign-up'}>
                  Đổi mật khẩu
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='auth-wrapper'>
        <div className='auth-inner'>
          <form>
            <h3>Đăng nhập</h3>
            <div className='form-group'>
              <label>Tài khoản</label>
              <input
                className='form-control'
                placeholder='Tài khoản'
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Mật khẩu</label>
              <input
                type='password'
                className='form-control'
                placeholder='Mật khẩu'
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onKeyDown={_handleKeyDown}
              />
            </div>

            <div className='form-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='customCheck1'
                  checked={isRemember}
                  onChange={() => setIsRemember(!isRemember)}
                />
                <label className='custom-control-label' htmlFor='customCheck1'>
                  Ghi nhớ phiên đăng nhập
                </label>
              </div>
            </div>

            <button
              type='button'
              className='btn btn-primary btn-block'
              onClick={() => memoizedCallback()}
            >
              Đăng nhập
            </button>
            <p className='forgot-password text-right'>
              <a href='/sign-up'>Đổi Mật khẩu?</a>
            </p>
          </form>
        </div>
      </div>
      <CusMotal
        isShow={isShow}
        okText={'OK'}
        cancelText={null}
        message={message}
        title={'Thông Báo'}
        onOK={() => {
          setIsShow(false);
        }}
        onClose={() => setIsShow(false)}
      />
    </div>
  );
};

export default Login;

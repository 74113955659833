import React from "react";
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

const CusModal = (props: any) => {
    return (
        <Modal style={{ marginTop: 50 }} show={props.isShow} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                {
                    props.cancelText &&
                    <Button variant="secondary" onClick={props.onCancel}>
                        {props.cancelText}
                    </Button>
                }
                <Button variant="primary" onClick={props.onOK}>
                    {props.okText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
CusModal.defaultProps = {
    isShow: false,
    title: 'Đăng Xuất',
    message: 'Bạn có muốn đăng xuất',
    cancelText: 'Hủy',
    okText: 'Đăng xuất'
}
export default CusModal;
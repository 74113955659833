import axios from 'axios';
import Config from './config/config';

axios.defaults.baseURL = Config.domain;

axios.interceptors.response.use(function (response) {

    return response;
}, function (error) {
  if (error.response.status === 401) {
   window.location.href = Config.url + 'sign-in';
    return;
  }

  return Promise.reject(error);
});

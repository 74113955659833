import { useState, useCallback, useEffect } from "react";
import { useCookies } from 'react-cookie'
import Service from './specialized.service';
import { SpecialInsertDTO, SpecializedDTO } from './specialzed.dto';
import Header from '../header/header.component';
import './specialized.css';
import readXlsxFile from 'read-excel-file';
import CusMotal from '../header/modal.component';
import CKEditor from 'ckeditor4-react';
import Config from "../../config/config";

const Specialized = () => {
    const [listSpecial, setListSpecial] = useState<SpecializedDTO[]>([]);
    const [specialEdit, setSpecialEdit] = useState<SpecializedDTO | null>(null);
    const [selectIndex, setSelectIndex] = useState(-1);
    const [refresh, setRefresh] = useState(true)
    const [session] = useCookies(['access_token_session']);
    const [countTotal, setCountTotal] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [lang, setLang] = useState<String>('');
    const [hasSearch, setHasSearch] = useState(false);

    useEffect(() => {
        CKEditor.editorConfig = function (config: any) {
            config.toolbar = Config.CKEDITOR;
        };
    }, []);

    useEffect(() => {
        Service.getSpecial(session.access_token_session, 0, lang).then((res) => {
            setListSpecial(res.data.specialData);
            setCountTotal(res.data.countTotal);
        }).catch(err => {
            console.log(err)
        })
    }, [refresh, session, lang])

    const handlePageChange = useCallback(
        () => {
            if (listSpecial.length < countTotal && window.innerHeight + document.documentElement.scrollTop === document.scrollingElement?.scrollHeight) {
                Service.getSpecial(session.access_token_session, listSpecial.length, lang).then((res) => {
                    let mList = listSpecial.slice(0, listSpecial.length);
                    mList.push(...res.data.specialData);
                    setListSpecial(mList);
                }).catch(err => {

                })
            }
        }, [session.access_token_session, listSpecial, countTotal, lang])

    useEffect(() => {
        window.addEventListener('scroll', handlePageChange);
        return (() => {
            window.removeEventListener('scroll', handlePageChange);
        })
    }, [handlePageChange]);

    const deleteSpecialCallback = useCallback(
        (id: String) => {
            if (window.confirm('Bạn có muốn xóa chuyên ngành này?')) {
                Service.deleteSpecial(session.access_token_session, id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Xóa chuyên ngành thành công');
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Xóa chuyên ngành thất bại');
                })
            }
        }, [refresh, session]);

    const updateSpecialCallback = useCallback(
        () => {
            if (specialEdit != null && specialEdit._id === '')
                Service.createSpecial(session.access_token_session, specialEdit).then((res) => {
                    if (res.status === 201) {
                        setIsShow(true);
                        setMessage('Thêm chuyên ngành thành công');
                        setRefresh(!refresh);
                        setSpecialEdit(null);
                        setSelectIndex(-1);
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Thêm chuyên ngành thất bại');
                })
            else if (specialEdit != null && specialEdit._id !== '') {
                Service.updateSpecial(session.access_token_session, specialEdit, specialEdit._id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Cập nhật chuyên ngành thành công');
                        setRefresh(!refresh);
                        setSpecialEdit(null);
                        setSelectIndex(-1);
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Cập nhật chuyên ngành thất bại');
                })
            }
        }, [specialEdit, refresh, session]);

    const createSpecialCallback = useCallback(
        () => {
            if (specialEdit != null)
                return;

            let mList = listSpecial.slice(0, listSpecial.length);
            let data: SpecializedDTO = {
                _id: '',
                specialName: '',
                specialDelDate: new Date(),
                specialGrammarContent: '',
                status: true,
                lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
            };
            mList.unshift(data);
            setSpecialEdit(data);
            setSelectIndex(0);
            setListSpecial(mList);
        }, [listSpecial, specialEdit, lang])

    const allowUpdateValue = useCallback(
        (index: number) => {
            if (specialEdit == null) {
                setSpecialEdit(listSpecial[index]);
                setSelectIndex(index);
            }
        }, [listSpecial, specialEdit]
    );

    const cancelUpdateValue = useCallback(
        (index: number) => {
            setSpecialEdit(null);
            setSelectIndex(-1);
            if (listSpecial[0]._id === '') {
                const mList = listSpecial.splice(1, listSpecial.length);
                setListSpecial(mList);
            }
        }, [listSpecial]
    )

    const updateValueName = useCallback(
        (index: number, value: String) => {
            setSpecialEdit((prev: any) => {
                return {
                    ...prev,
                    specialName: value
                }
            });
        }, []
    );

    const readExcel = useCallback((event: any) => {
        readXlsxFile(event.target.files[0], { sheet: 'Specialized' }).then((rows: any) => {
            console.log(rows);
            if (!!rows) {
                let Specials: any = [];
                rows.map((x: any, index: number) => {
                    if (index !== 0) {
                        const special: SpecialInsertDTO = {
                            specialName: (x[2] && x[2].toString()) || null,
                            specialId: x[1] && x[1].toString(),
                            specialGrammarContent: (x[3] && x[3].toString()) || null,
                            status: true,
                            lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
                        }
                        Specials.push(special);
                    }
                    return null;
                })
                console.log('Specials', Specials);

                Service.createManySpecialized(session.access_token_session, Specials).then((res: any) => {
                    if (res.status === 201) {
                        const success: number = res.data.filter((item: any) => {
                            if (item == null) return false;
                            else return true;
                        }).length;
                        setIsShow(true);
                        setMessage('Thêm thành công ' + success + ' chuyên ngành, thất bại ' + (res.data.length - success) + ' chuyên ngành');
                        setRefresh(!refresh)
                    }
                })
            }
        })
    }, [refresh, session, lang]);

    const onSearchResult = useCallback(
        (textSearch: String) => {
            if (textSearch !== '') {
                Service.searchSpecial(session.access_token_session, textSearch, lang).then(res => {
                    setListSpecial(res.data)
                }).catch(err => { });
                setHasSearch(true);
            } else {
                if (hasSearch) {
                    setRefresh(!refresh)
                    setHasSearch(false);
                }
            }
        }, [session.access_token_session, refresh, lang, hasSearch]);
    const onChangeLanguage = useCallback(
        (language: String) => {
            console.log("change language");
            if (language != lang) {
                setLang(language);
            }

        }, []);
    return (
        <div>
            <Header title={'Quản lý chuyên ngành'} pageActive={'SPECIAL'} textSearch={'tên chuyên ngành...'} onSearchResult={onSearchResult} onChangeLanguage={onChangeLanguage} />
            <table className="table table-hover" style={{ marginTop: 50 }}>
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>STT</th>
                        <th scope="col" style={{ width: '25%' }}>Tên chuyên ngành</th>
                        <th scope="col" style={{ width: '45%', textAlign: 'center' }}>Từ vựng của chuyên ngành</th>
                        <th scope="col" style={{ width: '20%', textAlign: 'center' }}>
                            <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { createSpecialCallback() }}>+</button>
                            <button type="button" className="btn btn-success btn-file" style={{ marginLeft: 10 }}>
                                <input type="file" id="input" onChange={readExcel} />
                                Nhập Excel
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listSpecial.map((x: SpecializedDTO, index: number) => {
                            return (
                                <tr key={x._id.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                    <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                    <td>{index === selectIndex && specialEdit != null ? <input className="form-control" placeholder="Chuyên ngành" value={specialEdit?.specialName.toString()} onChange={(event) => updateValueName(index, event.target.value)} /> : x.specialName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            index === selectIndex && specialEdit != null ?
                                                <CKEditor
                                                    data={specialEdit.specialGrammarContent}
                                                    onInit={(editor: any) => {
                                                        editor.editing.view.change((writer: any) => {
                                                            writer.setStyle(
                                                                "height",
                                                                "300px",
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });
                                                    }}
                                                    onChange={(event: any) => {
                                                        const data = event.editor.getData()
                                                        setSpecialEdit((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                specialGrammarContent: data
                                                            }
                                                        });
                                                    }}
                                                />
                                                :
                                                <div style={{ maxHeight: 220, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: x.specialGrammarContent?.toString() }} />
                                        }
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            index === selectIndex && specialEdit != null ?
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => updateSpecialCallback()}>Xác nhận</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => cancelUpdateValue(index)}>Hủy</button>
                                                </div>
                                                :
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => allowUpdateValue(index)}>Sửa</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => deleteSpecialCallback(x._id.toString())}>Xóa</button>
                                                </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }

                </tbody>
            </table>
            <CusMotal
                isShow={isShow}
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={() => {
                    setIsShow(false);
                }}
                onClose={() => setIsShow(false)}
            />
        </div>
    );
}
export default Specialized;
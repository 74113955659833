/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import './header.css';
import { useCookies } from 'react-cookie'
import { useHistory } from "react-router-dom";
import CusMotal from './modal.component';

const Header = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'access_token_session']);
    const history = useHistory();
    const [textSearch, setTextSearch] = useState('');
    const [lang, setLang] = useState('');
    const [isShow, setIsShow] = useState(false);

    const logout = () => {
        removeCookie('access_token');
        removeCookie('access_token_session');
        history.push('/sign-in');
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props.onSearchResult(textSearch);
        }, 500);

        return () => clearTimeout(delayDebounceFn)
    }, [textSearch, props]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (props.onChangeLanguage) {
                props.onChangeLanguage(lang);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn)
    }, [lang, props]);


    return (
        <nav className="navbar navbar-fixed-top flex flex-row" role="navigation">
            {/* <div className="navbar-header" style={{ marginLeft: 20 }}>
                {props.title}
            </div> */}
            <div className='language-container'>
                <select onChange={(e) => setLang(e.target.value)} className="form-control form-control-sm" style={{ flex: 1 }}>
                    <option value="">Tiếng Việt</option>
                    <option value="en">Tiếng Anh</option>
                </select>
            </div>
            <div className="search-container">
                <input type="text" placeholder={props.textSearch} name="search" onChange={(e) => setTextSearch(e.target.value)} />
                <button type="submit"><i className="fa fa-search"></i></button>
            </div>
            <ul className="nav navbar-top-links navbar-right">
                <li className="lazur-bg" >
                    <a href="/topic" className={history.location.pathname === '/topic' ? 'active' : ''}>
                        <i className="fa fa-book"></i> Chủ đề
                    </a>
                </li>
                <li className="lazur-bg">
                    <a href="/specialized" className={history.location.pathname === '/specialized' ? 'active' : ''}>
                        <i className="fa fa-sticky-note"></i> Chuyên ngành
                    </a>
                </li>

                <li className="lazur-bg">
                    <a href="/rule" className={history.location.pathname === '/rule' ? 'active' : ''}>
                        <i className="fa fa-sticky-note"></i> Quy tắc
                    </a>
                </li>
                <li className="lazur-bg">
                    <a href="/grammar" className={history.location.pathname === '/grammar' ? 'active' : ''}>
                        <i className="fa fa-list"></i> Ngữ pháp
                    </a>
                </li>
                <li className="lazur-bg">
                    <a href="/topik90" className={history.location.pathname === '/topik90' ? 'active' : ''}>
                        <i className="fa fa-home"></i> 90 Câu topik I
                    </a>
                </li>
                <li className="lazur-bg">
                    <a href="/topik100" className={history.location.pathname === '/topik100' ? 'active' : ''}>
                        <i className="fa fa-home"></i> 100 Câu topik II
                    </a>
                </li>
                <li className="lazur-bg">
                    <a href="/ads" className={history.location.pathname === '/ads' ? 'active' : ''}>
                        <i className="fa fa-sticky-note"></i> Quảng cáo
                    </a>
                </li>
                <CusMotal
                    isShow={isShow}
                    onOK={() => {
                        setIsShow(false);
                        logout();
                    }}
                    onClose={() => setIsShow(false)}
                    onCancel={() => setIsShow(false)}
                />
                <li className="lazur-bg" onClick={() => setIsShow(true)}>
                    <a href="#" style={{ marginRight: 20 }} >
                        <i className="fa fa-sign-out"></i> Đăng xuất
                    </a>
                </li>
            </ul>
        </nav>
    )
}
export default memo(Header);
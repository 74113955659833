import axios from "axios";
import Config from "../../config/config";
import { GrammarDTO } from "./choose.dto";

const Service = {
    getGrammar90TopikI(token: String, skip: number, lang: String): Promise<any> {
        return axios.get<Promise<GrammarDTO[]>>(Config.API_URL.GRAMMAR.TOPIKI90 + '?skip=' + skip + '&limit=100&lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    getGrammar100TopikII(token: String, skip: number, lang: String): Promise<any> {
        return axios.get<Promise<GrammarDTO[]>>(Config.API_URL.GRAMMAR.TOPIKII100 + '?skip=' + skip + '&limit=100&lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    updateGrammarTopik(token: String, id: String, data: any): Promise<any> {
        return axios.put<Promise<GrammarDTO[]>>(Config.API_URL.GRAMMAR.UPDATE_TOPIK_90_100 + '?_id=' + id, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async searchGrammar(params: String, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.GRAMMAR.SEARCH, { lang: lang, keyword: params }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },

    async searchGrammarTopik(keyword: String, grammarTopik: number, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.GRAMMAR.SEARCH_GRAMMAR_TOPIK, { lang: lang, keyword: keyword, grammarTopik: grammarTopik }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
}

export default Service;
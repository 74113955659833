import axios from 'axios';
import { GrammarDTO, GrammarInsertDTO } from './grammar.dto';
import Config from '../../config/config';

const Service = {
    getGrammar(token: String, skip: number, lang: String): Promise<any> {
        return axios.get<Promise<GrammarDTO[]>>(Config.API_URL.GRAMMAR.GET + '?skip=' + skip + '&lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    getTopic(token: String, lang: String): Promise<any> {
        return axios.get<Promise<any>>(Config.API_URL.TOPIC.GET_AUTO_COMPLETE + '?lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async deleteGrammar(token: String, _id: String): Promise<any> {
        return axios.delete<Promise<any>>(Config.API_URL.GRAMMAR.DELETE + '?_id=' + _id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })

    },

    async updateGrammar(token: String, x: any, _id?: String): Promise<any> {
        return axios.put<Promise<any>>(Config.API_URL.GRAMMAR.UPDATE + '?_id=' + _id, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createGrammar(token: String, x: any): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.GRAMMAR.CREATE, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createManyGrammar(token: String, x: GrammarInsertDTO[]): Promise<any> {
        return axios.post<Promise<GrammarDTO[]>>(Config.API_URL.GRAMMAR.CREATE_MANY, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async searchGrammar(params: String, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.GRAMMAR.SEARCH, { keyword: params, lang: lang }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
}

export default Service;
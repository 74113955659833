import { useState, useEffect } from "react";
import Config from '../../config/config';
import { useCookies } from 'react-cookie'
import Service from './choose.service';
import { GrammarDTO } from './choose.dto';
import Header from '../header/header.component';
import CKEditor from 'ckeditor4-react';
import CusMotal from '../header/modal.component';
import GrammarModal from "./modal.component";
import { useCallback } from "react";

const Grammar100 = () => {
    const [listGrammar, setListGrammar] = useState<GrammarDTO[]>([]);

    const [isShowAdd, setIsShowAdd] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [session] = useCookies(['access_token_session']);
    const [lang, setLang] = useState<String>('');
    const [hasSearch, setHasSearch] = useState(false);

    useEffect(() => {
        CKEditor.editorConfig = function (config: any) {
            config.toolbar = Config.CKEDITOR;
        };
    }, []);

    useEffect(() => {
        Service.getGrammar100TopikII(session.access_token_session, 0, lang).then((res) => {
            setListGrammar(res.data.grammarData);
        }).catch(err => {
            console.log(err)
        })
    }, [session.access_token_session, refresh, lang]);

    const removeGrammarCallback = useCallback(
        (data: GrammarDTO) => {
            Service.updateGrammarTopik(session.access_token_session, data._id, { grammarTopik: 0 }).then((res) => {
                if (res.status === 200) {
                    setIsShow(true);
                    setMessage('Xóa thành công');
                    setRefresh(!refresh)
                }
            }).catch(err => {
                setIsShow(true);
                setMessage('Xóa thất bại');
            });
        }, [session.access_token_session, refresh]
    );

    const addGrammarCallback = useCallback(
        () => {
            setIsShowAdd(true);
        }, []
    )

    const updateGrammarCallback = useCallback(
        (id: String) => {
            Service.updateGrammarTopik(session.access_token_session, id, { grammarTopik: 2 }).catch(err => {
            });
        }, [session.access_token_session]
    );

    const onSearchResult = useCallback(
        (textSearch: String) => {
            if (textSearch !== '') {
                Service.searchGrammarTopik(textSearch, 2, lang).then(res => {
                    setListGrammar(res.data);
                }).catch(err => { });
                setHasSearch(true);
            } else {
                if (hasSearch) {
                    setRefresh(!refresh)
                    setHasSearch(false);
                }
            }
        }, [refresh, lang, hasSearch]);
    const onChangeLanguage = useCallback(
        (language: String) => {
            console.log("change language");
            if (language != lang) {
                setLang(language);
            }

        }, []);
    return (
        <div>
            <Header title={'100 câu ngữ pháp topik II'} pageActive={'GRAMMAR'} textSearch={'100 câu ngữ pháp...'} onSearchResult={onSearchResult} onChangeLanguage={onChangeLanguage} />
            <table className="table" style={{ marginTop: 50 }}>
                <thead >
                    <tr>
                        <th scope="col" style={{ width: '5%', textAlign: 'center' }}>STT</th>
                        <th scope="col" style={{ width: '20%', textAlign: 'center' }}>Cấu trúc</th>
                        <th scope="col" style={{ width: '5%', textAlign: 'center' }}>Khó</th>
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Chủ đề</th>
                        <th scope="col" style={{ width: '25%', textAlign: 'center' }}>Nội dung</th>
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Tham khảo</th>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>
                            <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { addGrammarCallback() }}>+</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listGrammar.map((x: GrammarDTO, index: number) => {
                            return (
                                <tr key={x._id.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                    <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                    <td style={{ textAlign: 'center' }}>{x.grammarName}</td>
                                    <td style={{ textAlign: 'center' }}>{x.grammarRating}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {x.grammarOfTopic?.topicName} <br /> Topik {x.grammarOfTopic?.topik}
                                    </td>
                                    <td><div style={{ maxHeight: 220, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: x.grammarContent?.toString() }} /></td>
                                    <td style={{ textAlign: 'center' }}>{x.grammarRefer}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => removeGrammarCallback(x)}>Xóa</button>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <CusMotal
                isShow={isShow}
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={() => {
                    setIsShow(false);
                }}
                onClose={() => setIsShow(false)}
            />

            <GrammarModal
                isShow={isShowAdd}
                okText={'Rời khỏi'}
                cancelText={null}
                title={'Thêm ngữ pháp'}
                lang={lang}
                AddGrammar={(data: GrammarDTO) => {
                    if (listGrammar.find((x: GrammarDTO) => x._id === data._id) === undefined) {
                        let mList = [...listGrammar];
                        mList.unshift(data);
                        setListGrammar(mList);
                        updateGrammarCallback(data._id);
                    }
                }}
                onOK={() => {
                    setIsShowAdd(false)
                    setRefresh(!refresh);
                }}
            />
        </div>
    );
}
export default Grammar100;
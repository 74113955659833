import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { GrammarDTO } from "./choose.dto";
import Service from "./choose.service";

const GrammarModal = (props: any) => {
    const [listGrammar, setListGrammar] = useState<GrammarDTO[]>([]);
    const [textSearch, setTextSearch] = useState('');

    const onSearchResult = useCallback(
        (textSearch: String) => {
            console.log(props.lang);
            if (textSearch !== '')
                Service.searchGrammar(textSearch, props.lang).then(res => {
                    setListGrammar(res.data);
                }).catch(err => { });
            else setListGrammar([]);
        }, [props.lang]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onSearchResult(textSearch);
        }, 500);

        return () => clearTimeout(delayDebounceFn)
    }, [onSearchResult, textSearch])

    const AddGrammar = useCallback(
        (x: GrammarDTO, index: number) => {
            const mList = listGrammar.filter(item => item._id !== x._id);
            setListGrammar(mList);
            props.AddGrammar(x);
        }, [listGrammar, props]
    );

    return (
        <Modal style={{ marginTop: 50 }} show={props.isShow} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="search-container" style={{ width: '100%' }}>
                    <input type="text" placeholder='Tìm kiếm ngữ pháp' name="search" onChange={(e) => setTextSearch(e.target.value)} />
                    <button type="submit"><i className="fa fa-search"></i></button>
                </div>
                <div style={{ height: 400, overflowY: 'scroll' }}>
                    <table className="table">
                        <thead >
                            <tr>
                                <th scope="col" style={{ width: '40%', textAlign: 'center' }}>Cấu trúc</th>
                                <th scope="col" style={{ width: '25%', textAlign: 'center' }}>Chủ đề</th>
                                <th scope="col" style={{ width: '25%', textAlign: 'center' }}>Chú giải</th>
                                <th scope="col" style={{ width: '10%', textAlign: 'center' }}>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listGrammar.map((x: GrammarDTO, index: number) => {
                                    return (
                                        <tr key={x._id.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                            <td style={{ textAlign: 'center' }}>{x.grammarStruct}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                {x.grammarOfTopic?.topicName} <br /> Topik {x.grammarOfTopic?.topik}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{x.grammarExplain}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button type="button" className="btn btn-primary" onClick={() => AddGrammar(x, index)}>Thêm</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    props.cancelText &&
                    <Button variant="secondary" onClick={props.onCancel}>
                        {props.cancelText}
                    </Button>
                }
                <Button variant="primary" onClick={props.onOK}>
                    {props.okText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
GrammarModal.defaultProps = {
    isShow: false,
    title: 'Thêm ngữ pháp',
    cancelText: 'Hủy',
    okText: 'Thêm'
}
export default GrammarModal;
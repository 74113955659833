import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from "../../config/config";
import { useHistory } from "react-router-dom";
import CusMotal from '../header/modal.component';

const SignUp = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');

    const memoizedCallback = useCallback(
        () => {
            if(userName === ''){
                setIsShow(true);
                setMessage('Tài khoản không được để trống');
                return;
            }else if(password === ''){
                setIsShow(true);
                setMessage('Mật khẩu không được để trống');
                return;
            }else if(newPassword === '' || repeatNewPassword === ''){
                setIsShow(true);
                setMessage('Nhập mật khẩu mới');
                return;
            }else if(newPassword !== repeatNewPassword){
                setIsShow(true);
                setMessage('Mật khẩu mới không giống nhau');
                return;
            }
            const myCipher = Config.cipher('account');
            axios.post(Config.API_URL.CHANGE_PASSWORD, {
                userName: userName,
                password: myCipher(password),
                newPassword: myCipher(newPassword)
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (!!res.data) {
                    setIsShow(true);
                    setMessage('Đổi mật khẩu thành công');
                    history.push("/sign-in");
                }
            }).catch(err => {
                setIsShow(true);
                setMessage('Tài khoản hoặc mật khẩu không đúng');
            })
            
        },
        [userName, password, newPassword, repeatNewPassword, history]);

    const _handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            memoizedCallback();
        }
    }, [memoizedCallback]);

    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container">
                    <Link className="navbar-brand" to={"/sign-in"}>Tra cứu từ điển</Link>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/sign-in"}>Đăng nhập</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/sign-up"}>Đổi mật khẩu</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <form>
                        <h3>Đổi mật khẩu</h3>

                        <div className="form-group">
                            <label>Tên đăng nhập</label>
                            <input type="text" className="form-control" placeholder="tên đăng nhập" value={userName} onChange={(event) => setUserName(event.target.value)}/>
                        </div> 
                        <div className="form-group">
                            <label>Mật khẩu cũ</label>
                            <input type="password" className="form-control" placeholder="mật khẩu cũ" value={password} onChange={(event) => setPassword(event.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Mật khẩu mới</label>
                            <input type="password" className="form-control" placeholder="mật khẩu mới" value={newPassword} onChange={(event) => setNewPassword(event.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Nhập lại mật khẩu</label>
                            <input type="password" className="form-control" placeholder="nhập lại mật khẩu" value={repeatNewPassword} onChange={(event) => setRepeatNewPassword(event.target.value)} onKeyDown={_handleKeyDown}/>
                        </div>

                        <button type="button" className="btn btn-primary btn-block" onClick={memoizedCallback}>Đổi mật khẩu</button>
                        <p className="forgot-password text-right">
                            Quay lại trang <a href="/sign-in">đăng nhập?</a>
                        </p>
                    </form>
                </div>
            </div>
            <CusMotal 
                isShow={isShow} 
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={()=>{
                    setIsShow(false);
                }}
                onClose={()=> setIsShow(false)}
            />
        </div>
    );
}
export default SignUp;
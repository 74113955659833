import axios from 'axios';
import { TopicDTO, TopicInsertDTO } from './topic.dto';
import Config from '../../config/config';

const Service = {
    async getTopic(token: String, skip: number, lang: String): Promise<any> {
        return axios.get(Config.API_URL.TOPIC.GET + '?skip=' + skip + '&lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async deleteTopic(token: String, _id?: String): Promise<any> {
        return axios.delete<Promise<TopicDTO[]>>(Config.API_URL.TOPIC.DELETE + '?_id=' + _id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async updateTopic(token: String, x: TopicDTO, _id?: String): Promise<any> {
        return axios.put<Promise<TopicDTO[]>>(Config.API_URL.TOPIC.UPDATE + '?_id=' + _id, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createTopic(token: String, x: TopicDTO): Promise<any> {
        return axios.post<Promise<TopicDTO[]>>(Config.API_URL.TOPIC.CREATE, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createManyTopics(token: String, x: TopicInsertDTO[]): Promise<any> {
        return axios.post<Promise<TopicDTO[]>>(Config.API_URL.TOPIC.CREATE_MANY, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async searchTopic(token: String, params: String, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.TOPIC.SEARCH, { lang: lang, keyword: params }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    }
}

export default Service;
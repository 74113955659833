import axios from 'axios';
import { SpecialInsertDTO, SpecializedDTO } from './specialzed.dto';
import Config from '../../config/config';

const Service = {
    getSpecial(token: String, skip: number, lang: String): Promise<any> {
        return axios.get<Promise<SpecializedDTO[]>>(Config.API_URL.SPECIAL.GET + '?skip=' + skip + "&lang=" + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    getTopic(token: String, lang: String): Promise<any> {
        return axios.get<Promise<any>>(Config.API_URL.TOPIC.GET_AUTO_COMPLETE + '?lang=' + lang, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async deleteSpecial(token: String, _id: String): Promise<any> {
        return axios.delete<Promise<any>>(Config.API_URL.SPECIAL.DELETE + '?_id=' + _id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })

    },

    async updateSpecial(token: String, x: any, _id: String): Promise<any> {
        return axios.put<Promise<any>>(Config.API_URL.SPECIAL.UPDATE + '?_id=' + _id, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createSpecial(token: String, x: any): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.SPECIAL.CREATE, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async createManySpecialized(token: String, x: SpecialInsertDTO[]): Promise<any> {
        return axios.post<Promise<SpecializedDTO[]>>(Config.API_URL.SPECIAL.CREATE_MANY, x, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    },

    async searchSpecial(token: String, params: String, lang: String): Promise<any> {
        return axios.post<Promise<any>>(Config.API_URL.SPECIAL.SEARCH, { keyword: params, lang: lang }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Beare ' + token
            }
        })
    }
}

export default Service;
import { useState, useCallback, useEffect } from "react";
import { useCookies } from 'react-cookie'
import Service from './rulepronce.service';
import { RuleInsertDTO, RuleDTO } from './rulepronce.dto';
import Header from '../header/header.component';
import './rulepronce.css';
import readXlsxFile from 'read-excel-file';
import CusMotal from '../header/modal.component';
import CKEditor from 'ckeditor4-react';
import Config from "../../config/config";

const RulePronce = () => {
    const [listRule, setListRule] = useState<RuleDTO[]>([]);
    const [ruleEdit, setRuleEdit] = useState<RuleDTO | null>(null);
    const [selectIndex, setSelectIndex] = useState(-1);
    const [refresh, setRefresh] = useState(true)
    const [session] = useCookies(['access_token_session']);
    const [countTotal, setCountTotal] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [lang, setLang] = useState<String>('');
    const [hasSearch, setHasSearch] = useState(false);

    useEffect(() => {
        CKEditor.editorConfig = function (config: any) {
            config.toolbar = Config.CKEDITOR;
        };
    }, []);

    useEffect(() => {
        Service.getRule(session.access_token_session, 0, lang).then((res) => {
            setListRule(res.data.ruleData);
            setCountTotal(res.data.countTotal);
        }).catch(err => {
            console.log(err)
        })
    }, [refresh, session, lang])

    const handlePageChange = useCallback(
        () => {
            if (listRule.length < countTotal && window.innerHeight + document.documentElement.scrollTop === document.scrollingElement?.scrollHeight) {
                Service.getRule(session.access_token_session, listRule.length, lang).then((res) => {
                    let mList = listRule.slice(0, listRule.length);
                    mList.push(...res.data.ruleData);
                    setListRule(mList);
                }).catch(err => {

                })
            }
        }, [session.access_token_session, listRule, countTotal, lang])

    useEffect(() => {
        window.addEventListener('scroll', handlePageChange);
        return (() => {
            window.removeEventListener('scroll', handlePageChange);
        })
    }, [handlePageChange]);

    const deleteRuleCallback = useCallback(
        (id: String) => {
            if (window.confirm('Bạn có muốn xóa quy tắc này?')) {
                Service.deleteRule(session.access_token_session, id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Xóa quy tắc phát âm thành công');
                        setRefresh(!refresh)
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Xóa quy tắc phát âm thất bại');
                })
            }
        }, [refresh, session]);

    const updateRuleCallback = useCallback(
        () => {
            if (ruleEdit != null && ruleEdit._id === '')
                Service.createRule(session.access_token_session, ruleEdit).then((res) => {
                    if (res.status === 201) {
                        setIsShow(true);
                        setMessage('Thêm quy tắc phát âm thành công');
                        setRefresh(!refresh);
                        setRuleEdit(null);
                        setSelectIndex(-1);
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Thêm quy tắc phát âm thất bại');
                })
            else if (ruleEdit != null && ruleEdit._id !== '') {
                Service.updateRule(session.access_token_session, ruleEdit, ruleEdit._id).then((res) => {
                    if (res.status === 200) {
                        setIsShow(true);
                        setMessage('Cập nhật quy tắc phát âm thành công');
                        setRefresh(!refresh);
                        setRuleEdit(null);
                        setSelectIndex(-1);
                    }
                }).catch(err => {
                    setIsShow(true);
                    setMessage('Cập nhật quy tắc phát âm thất bại');
                })
            }
        }, [ruleEdit, refresh, session]);

    const createRuleCallback = useCallback(
        () => {
            if (ruleEdit != null)
                return;
            console.log("listRule", listRule)
            let mList = listRule.slice(0, listRule.length);
            let data: RuleDTO = {
                _id: '',
                ruleName: '',
                ruleDelDate: new Date(),
                ruleContent: '',
                status: true,
                lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
            };
            mList.unshift(data);
            setRuleEdit(data);
            setSelectIndex(0);
            setListRule(mList);
        }, [listRule, ruleEdit, lang])

    const allowUpdateValue = useCallback(
        (index: number) => {
            if (ruleEdit == null) {
                setRuleEdit(listRule[index]);
                setSelectIndex(index);
            }
        }, [listRule, ruleEdit]
    );

    const cancelUpdateValue = useCallback(
        (index: number) => {
            setRuleEdit(null);
            setSelectIndex(-1);
            if (listRule[0]._id === '') {
                const mList = listRule.splice(1, listRule.length);
                setListRule(mList);
            }
        }, [listRule]
    )

    const updateValueName = useCallback(
        (index: number, value: String) => {
            setRuleEdit((prev: any) => {
                return {
                    ...prev,
                    ruleName: value
                }
            });
        }, []
    );

    const readExcel = useCallback((event: any) => {
        readXlsxFile(event.target.files[0], { sheet: 'Rule' }).then((rows: any) => {
            console.log(rows);
            if (!!rows) {
                let Rules: any = [];
                rows.map((x: any, index: number) => {
                    if (index !== 0) {
                        const rule: RuleInsertDTO = {
                            ruleName: (x[2] && x[2].toString()) || null,
                            ruleId: x[1] && x[1].toString(),
                            ruleContent: (x[3] && x[3].toString()) || null,
                            status: true,
                            lang: Array.isArray(lang) && lang.length > 0 ? lang[0] : lang,
                        }
                        Rules.push(rule);
                    }
                    return null;
                })
                Service.createManyRule(session.access_token_session, Rules).then((res: any) => {
                    if (res.status === 201) {
                        const success: number = res.data.filter((item: any) => {
                            if (item == null) return false;
                            else return true;
                        }).length;
                        setIsShow(true);
                        setMessage('Thêm thành công ' + success + ' quy tắc phát âm, thất bại ' + (res.data.length - success) + ' quy tắc phát âm');
                        setRefresh(!refresh)
                    }
                })
            }
        })
    }, [refresh, session, lang]);

    const onSearchResult = useCallback(
        (textSearch: String) => {
            if (textSearch !== '') {
                Service.searchRule(session.access_token_session, textSearch, lang).then(res => {
                    setListRule(res.data)
                }).catch(err => { });
                setHasSearch(true);
            } else {
                if (hasSearch) {
                    setRefresh(!refresh)
                    setHasSearch(false);
                }
            }
        }, [session.access_token_session, refresh, lang, hasSearch]);
    const onChangeLanguage = useCallback(
        (language: String) => {
            console.log("change language");
            if (language != lang) {
                setLang(language);
            }

        }, []);
    return (
        <div>
            <Header title={'Quản lý quy tắc phát âm'} pageActive={'RULE'} textSearch={'tên quy tắc phát âm...'} onSearchResult={onSearchResult} onChangeLanguage={onChangeLanguage} />
            <table className="table table-hover" style={{ marginTop: 50 }}>
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '10%', textAlign: 'center' }}>STT</th>
                        <th scope="col" style={{ width: '25%' }}>Tên quy tắc phát âm</th>
                        <th scope="col" style={{ width: '45%', textAlign: 'center' }}>Từ vựng của quy tắc phát âm</th>
                        <th scope="col" style={{ width: '20%', textAlign: 'center' }}>
                            <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => { createRuleCallback() }}>+</button>
                            <button type="button" className="btn btn-success btn-file" style={{ marginLeft: 10 }}>
                                <input type="file" id="input" onChange={readExcel} />
                                Nhập Excel
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listRule.map((x: RuleDTO, index: number) => {
                            return (
                                <tr key={x._id.toString()} style={{ backgroundColor: '#FFFFFF' }}>
                                    <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                    <td>{index === selectIndex && ruleEdit != null ? <input className="form-control" placeholder="Quy tắc phát âm" value={ruleEdit?.ruleName && ruleEdit?.ruleName.toString()} onChange={(event) => updateValueName(index, event.target.value)} /> : x.ruleName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            index === selectIndex && ruleEdit != null ?
                                                <CKEditor
                                                    data={ruleEdit.ruleContent}
                                                    onInit={(editor: any) => {
                                                        editor.editing.view.change((writer: any) => {
                                                            writer.setStyle(
                                                                "height",
                                                                "300px",
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });
                                                    }}
                                                    onChange={(event: any) => {
                                                        const data = event.editor.getData()
                                                        setRuleEdit((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                ruleContent: data
                                                            }
                                                        });
                                                    }}
                                                />
                                                :
                                                <div style={{ maxHeight: 220, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: x.ruleContent?.toString() }} />
                                        }
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {
                                            index === selectIndex && ruleEdit != null ?
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => updateRuleCallback()}>Xác nhận</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => cancelUpdateValue(index)}>Hủy</button>
                                                </div>
                                                :
                                                <div>
                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 10 }} onClick={() => allowUpdateValue(index)}>Sửa</button>
                                                    <button type="button" className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => deleteRuleCallback(x._id.toString())}>Xóa</button>
                                                </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }

                </tbody>
            </table>
            <CusMotal
                isShow={isShow}
                okText={'OK'}
                cancelText={null}
                message={message}
                title={'Thông Báo'}
                onOK={() => {
                    setIsShow(false);
                }}
                onClose={() => setIsShow(false)}
            />
        </div>
    );
}
export default RulePronce;